import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

import Layout from "../layout/Layout.vue";
import LayoutHome from "../layout/LayoutHome.vue";
import LayoutClinic from "../layout/LayoutClinic.vue";
import LayoutClinicCust from "../layout/LayoutClinicCust.vue";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "forgetPassword",
        name: "forgetPassword",
        meta: { title: "忘记密码" },
        component: () =>
          import(
            /* webpackChunkName: 'forgetPassword'*/ "@/views/Login/forgetPassword.vue"
          ),
      },
      {
        path: "fixPassword",
        name: "fixPassword",
        meta: { title: "修改密码" },
        component: () =>
          import(
            /* webpackChunkName: 'fixPassword'*/ "@/views/Login/fixPassword.vue"
          ),
      },
      {
        path: "clinical",
        name: "clinical",
        meta: { title: "中医临床" },
        component: () =>
          import(
            /* webpackChunkName: 'orderlist'*/ "@/views/clinical/index.vue"
          ),
      },
      {
        path: "clinical2",
        name: "clinical2",
        meta: { title: "中西医结合" },
        component: () =>
          import(
            /* webpackChunkName: 'orderlist'*/ "@/views/clinical2/index.vue"
          ),
      },
      {
        path: "channels",
        name: "channels",
        meta: { title: "中医AI" },
        component: () =>
          import(
            /* webpackChunkName: 'channels'*/ "@/views/channels/index.vue"
          ),
      },
      {
        path: "slow",
        name: "slow",
        meta: { title: "慢性疾病" },
        component: () =>
          import(
            /* webpackChunkName: 'channels'*/ "@/views/slow/index.vue"
          ),
      },
      {
        path: "physique",
        name: "physique",
        meta: { title: "体质辨识" },
        component: () =>
          import(
            /* webpackChunkName: 'physique'*/ "@/views/physique/index.vue"
          ),
      },
      {
        path: "covid19",
        name: "covid19",
        meta: { title: "新冠专题" },
        component: () =>
          import(/* webpackChunkName: 'covid19'*/ "@/views/covid19/index.vue"),
      },
      {
        path: "coronavirus",
        name: "coronavirus",
        meta: { title: "新冠专题" },
        component: () =>
          import(
            /* webpackChunkName: 'channels'*/ "@/views/coronavirus/index.vue"
          ),
      },
      {
        path: "programme",
        name: "programme",
        meta: { title: "新冠专题" },
        component: () =>
          import(
            /* webpackChunkName: 'channels'*/ "@/views/coronavirus/programme.vue"
          ),
      },
      {
        path: "clinic",
        name: "clinic",
        meta: { title: "诊所入驻申请" },
        component: () =>
          import(/* webpackChunkName: 'clinic'*/ "@/views/Login/clinic.vue"),
      },
      {
        path: "demonstrate",
        name: "demonstrate",
        meta: { title: "操作演示" },
        component: () =>
          import(
            /* webpackChunkName: 'physique'*/ "@/views/demonstrate/index.vue"
          ),
      },
      {
        path: "videoConsultation",
        name: "videoConsultation",
        meta: { title: "视频咨询" },
        component: () =>
          import(
            /* webpackChunkName: 'videoConsultation'*/ "@/views/videoConsultation/index.vue"
          ),
      },
      {
        path: "doctorVideo",
        name: "doctorVideo",
        meta: { title: "视频咨询" },
        component: () =>
          import(
            /* webpackChunkName: 'physique'*/ "@/views/doctorVideo/index.vue"
          ),
      },
      {
        path: "agora",
        name: "agora",
        meta: { title: "视频咨询" },
        component: () =>
          import(
            /* webpackChunkName: 'physique'*/ "@/views/doctorVideo/agora.vue"
          ),
      },
      {
        path: "case",
        name: "case",
        meta: { title: "就诊病例" },
        component: () =>
          import(
            /* webpackChunkName: 'orderlist'*/ "@/views/case/index.vue"
          ),
      },
      {
        path: "healthy",
        name: "healthy",
        meta: { title: "中医康养" },
        component: () =>
          import(
            /* webpackChunkName: 'channels'*/ "@/views/healthy/index.vue"
          ),
      },
      {
        path: "acupuncture",
        name: "acupuncture",
        meta: { title: "中医针灸" },
        component: () =>
          import(
            /* webpackChunkName: 'acupuncture'*/ "@/views/acupuncture/index.vue"
          ),
      },
      {
        path: "knowledge",
        name: "knowledge",
        meta: { title: "知识库" },
        component: () =>
          import(
            /* webpackChunkName: 'knowledge'*/ "@/views/knowledge/index.vue"
          ),
      },
      {
        path: "knowledgeDetail",
        name: "knowledgeDetail",
        meta: { title: "知识库" },
        component: () =>
          import(
            /* webpackChunkName: 'knowledge'*/ "@/views/knowledge/detail.vue"
          ),
      },
      {
        path: "application",
        name: "application",
        meta: { title: "中医贴敷" },
        component: () =>
          import(
            /* webpackChunkName: 'application'*/ "@/views/application/index.vue"
          ),
      },
    ],
  },
  {
    path: "/",
    component: LayoutHome,
    redirect: "/index",
    children: [
      {
        path: "home",
        name: "home",
        meta: { title: "首页" },
        component: () =>
          import(/* webpackChunkName: 'home'*/ "@/views/Home.vue"),
      },
      {
        path: "orderList",
        name: "orderList",
        meta: { title: "我的订单" },
        component: () =>
          import(/* webpackChunkName: 'orderlist'*/ "@/views/order/index.vue"),
      },
      {
        path: "diagnoseList",
        name: "diagnoseList",
        meta: {
          title: "我的就诊",
          keepAlive: true
        },
        component: () =>
          import(
            /* webpackChunkName: 'diagnoseList'*/ "@/views/diagnose/index.vue"
          ),
      },
      {
        path: "diagnoseHistory",
        name: "diagnoseHistory",
        meta: { title: "就诊历史" },
        component: () =>
          import(
            /* webpackChunkName: 'diagnoseHistory'*/ "@/views/diagnose/history.vue"
          ),
      },
      {
        path: "userCenter",
        name: "userCenter",
        meta: { title: "个人中心" },
        component: () =>
          import(/* webpackChunkName: 'userCenter'*/ "@/views/user/index.vue"),
      },
      {
        path: "patient",
        name: "patient",
        meta: { title: "我的患者" },
        component: () =>
          import(/* webpackChunkName: 'userCenter'*/ "@/views/patient/index.vue"),
      },
      {
        path: "report",
        name: "report",
        meta: { title: "体检报告" },
        component: () =>
          import(
            /* webpackChunkName: 'report'*/ "@/views/report/index.vue"
          ),
      },
      {
        path: "printPreview",
        name: "printPreview",
        meta: { title: "辅助医疗报告" },
        component: () =>
          import(
            /* webpackChunkName: 'report'*/ "@/views/printPreview/index.vue"
          ),
      },
    ],
  },
  {
    path: "/clinicManager",
    component: LayoutClinic,
    redirect: "/clinicManager/index",
    children: [
      {
        path: "index",
        name: "clinicManager",
        meta: { title: "诊所管理" },
        component: () =>
          import(/* webpackChunkName: 'home'*/ "@/views/clinic/index.vue"),
      },
      {
        path: "board",
        name: "board",
        meta: { title: "数据看板" },
        component: () =>
          import(/* webpackChunkName: 'home'*/ "@/views/clinic/board.vue"),
      },
      {
        path: "finance",
        name: "finance",
        meta: { title: "财务管理" },
        component: () =>
          import(/* webpackChunkName: 'home'*/ "@/views/clinic/finance.vue"),
      },
      {
        path: "doctor",
        name: "clinicDoctor",
        meta: { title: "医生列表" },
        component: () =>
          import(/* webpackChunkName: 'home'*/ "@/views/clinic/doctor.vue"),
      },
      {
        path: "order",
        name: "clinicOrder",
        meta: { title: "订单管理" },
        component: () =>
          import(/* webpackChunkName: 'home'*/ "@/views/clinicOrder/index.vue"),
      },
      {
        path: "equipment",
        name: "clinicEquipment",
        meta: { title: "设备管理" },
        component: () =>
          import(/* webpackChunkName: 'home'*/ "@/views/clinicEquipment/index.vue"),
      },
      {
        path: "equipmentRecord",
        name: "clinicEquipmentRecord",
        meta: { title: "设备采集记录" },
        component: () =>
          import(/* webpackChunkName: 'home'*/ "@/views/clinicEquipment/record.vue"),
      },
      {
        path: "equipmentReport",
        name: "equipmentReport",
        meta: { title: "体检报告" },
        component: () =>
          import(
            /* webpackChunkName: 'home'*/ "@/views/report/index.vue"
          ),
      },
      {
        path: "doctorCustom",
        name: "clinicDoctorCustom",
        meta: { title: "共享列表" },
        component: () =>
          import(/* webpackChunkName: 'home'*/ "@/views/clinic/custom.vue"),
      },
      {
        path: "clinicDiagnose",
        name: "clinicDiagnose",
        meta: { title: "就诊管理" },
        component: () =>
          import(/* webpackChunkName: 'home'*/ "@/views/clinicDiagnose/index.vue"),
      },
      {
        path: "clinicPatient",
        name: "clinicPatient",
        meta: { title: "患者管理" },
        component: () =>
          import(/* webpackChunkName: 'home'*/ "@/views/clinicPatient/index.vue"),
      },
    ],
  },
  {
    path: "/clinicManagerCust",
    component: LayoutClinicCust,
    redirect: "/clinicManagerCust/index",
    children: [
      {
        path: "index",
        name: "clinicManagerCust",
        meta: { title: "诊所管理" },
        component: () => import(/* webpackChunkName: 'home'*/ "@/views/clinic/index.vue")
      },
      {
        path: "doctorCust",
        name: "clinicDoctorCust",
        meta: { title: "医生列表" },
        component: () =>
          import(/* webpackChunkName: 'home'*/ "@/views/clinic/doctor.vue"),
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    meta: { title: "登录页面" },
    component: () =>
      import(/* webpackChunkName: 'login'*/ "@/views/Login/index.vue"),
  },
  {
    path: "/expertLogin",
    name: "expertLogin",
    meta: { title: "登录页面" },
    component: () =>
      import(
        /* webpackChunkName: 'expertLogin'*/ "@/views/Login/indexExpert.vue"
      ),
  },
  {
    path: "/printAndroid",
    meta: { title: "体检报告" },
    component: () =>
      import(/* webpackChunkName: 'printAndroid'*/ "@/views/printAndroid/index.vue"),
  },
  {
    path: "/printReportAndroid",
    meta: { title: "体检报告" },
    component: () =>
      import(/* webpackChunkName: 'printReportAndroid'*/ "@/views/printPreview/index.vue"),
  },
  {
    path: "/printReportAndroidSimple",
    meta: { title: "体检报告" },
    component: () =>
      import(/* webpackChunkName: 'printReportAndroidSimple'*/ "@/views/printPreview/simplePrint.vue"),
  },
  {
    path: "/printPre",
    name: "printPre",
    meta: { title: "体检报告" },
    component: () =>
      import(/* webpackChunkName: 'printPreview'*/ "@/views/printPreview/index.vue"),
  },
  {
    path: "/printWebview",
    name: "printWebview",
    meta: { title: "体检报告" },
    component: () =>
      import(/* webpackChunkName: 'printWebview'*/ "@/views/printWebView/index.vue"),
  },
  {
    path: "/simplePrintWebview",
    name: "simplePrintWebview",
    meta: { title: "体检报告" },
    component: () =>
      import(/* webpackChunkName: 'printWebview'*/ "@/views/printWebView/simplePrint.vue"),
  },
  // {
  //   path: '*',
  //   redirect: '/index',
  // },
];

const router = new VueRouter({
  mode: 'hash',
  routes
});
router.beforeEach((to, from, next) => {
  store.commit("setPatientId", "");
  if (
    to.path !== "/login" &&
    to.path != "/forgetPassword" &&
    to.path != "/clinic" &&
    to.path != "/demonstrate" &&
    to.path != "/expertLogin" &&
    to.path != "/printWebview" &&
    to.path != "/simplePrintWebview"
  ) {
    const userInfo = JSON.parse(sessionStorage.getItem("userInfo") || "{}");
    if (!userInfo.token) {
      next({ path: "/login" });
    } else {
      store.commit("user/setIsLogin", true);
      isNonMedicalRouter(to, from, next);
      bossRouter(to, from, next);
      customerManageTypesRouter(to, from, next);
      next();
    }
  } else {
    next();
  }
});

// 非医端路由拦截
const isNonMedicalRouter = (to, from, next) => {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo") || "{}");
  const limitRouterName = [
    "orderList", "diagnoseList", "diagnoseHistory",
    "clinicManager", "clinicDoctor", "clinicOrder",
    "clinicManagerCust", "clinicManclinicDoctorCustager",
  ];
  if (userInfo?.customerAccountType == 2 || userInfo?.doctorAccountType == 2) {
    if (limitRouterName.indexOf(to.name) < 0) {
      return next();
    }
    return next({ path: '/home' });
  }
};

// 诊所老板路由拦截
const bossRouter = (to, from, next) => {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo") || "{}");
  const bossRouterPathName = [
    'clinicManager', 'clinicDoctor', 'clinicOrder', 'clinicDiagnose',
    'clinicManagerCust', 'clinicDoctorCust', 'clinicDoctorCustom'
  ];
  // customerTypes，1：连锁诊所老板， 2：诊所老板， 3：医生
  const customerTypes = userInfo?.customerTypes;
  if (customerTypes == 3) {
    if (bossRouterPathName.indexOf(to.name) > -1) {
      return next({ path: '/home' });
    }
  }
}

// customerManageTypes 路由拦截
const customerManageTypesRouter = (to, from, next) => {
  // 1中医临床 2中西医结合 3中医AI 4慢性疾病 5体质辨识 6中医康养 9中医针灸
  const limitRouterName = ["", "clinical", "clinical2", "channels", "slow", "physique", "healthy", "", "", "acupuncture", "knowledge"];
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo") || "{}");
  const customerManageTypesRouter = userInfo?.customerManageTypes?.split(',') ?? [];
  const limitRouterNameFilter = [];
  // 过滤需要限制的路由
  limitRouterName.forEach((routerName, index) => {
    if (customerManageTypesRouter.indexOf(`${index}`) < 0) {
      limitRouterNameFilter.push(routerName);
    }
  })
  const limitIndex = limitRouterNameFilter.indexOf(to.name);
  if (limitIndex > -1) {
    return next({ path: '/home' });
  }
};

export default router;
