<template>
  <div class="main-home">
    <div class="main">
      <div class="main-left">
        <div class="user-wrap">
          <img class="user-avatar" @click="goto('home', curIndex)" src="@/assets/images/user_nav_icon.png" alt="">
          <div class="user-name">{{ userInfo.userName }}</div>
          <div class="phone">{{ userInfo.loginName }}</div>
        </div>
        <div class="list">
          <div 
            v-for="(menu, index) in menuList" 
            :class="['list-item', curIndex === index && 'active']" 
            :key="index" 
            @click="goto(menu.pathName, index)"
            >{{ menu.title }}</div>
        </div>
      </div>
      <div class="main-right">
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Main',
  data() {
    return {
      curIndex: null,
      menuList: [
        { title: '我的患者', pathName: 'patient' },
        { title: '我的订单', pathName: 'orderList' },
        { title: '我的就诊', pathName: 'diagnoseList' },
        { title: '个人中心', pathName: 'userCenter' }
      ]
    }
  },
  watch: {
    $route(to, from) {
      const name = to.name
      this.filterCurIndex(name)
    }
  },
  created() {
    // 非医端隐藏 我的订单&我的就诊
    if (this.isNonMedical) {
      this.menuList = [
        // { title: '我的患者', pathName: 'patient' },
        { title: '个人中心', pathName: 'userCenter' }
      ]
    }
    const { name } = this.$route
    this.filterCurIndex(name)
  },
  computed: {
      ...mapState({
        userInfo: state => state.user.userInfo,
        isLogin: state => state.user.isLogin,
        isNonMedical: (state) => state.user.isNonMedical
      }),
    },
    methods: {
      goto(name, index) {
        this.curIndex = index
        this.$router.push({ name })
      },
      filterCurIndex(name) {
        this.curIndex = this.menuList.findIndex(menu => menu.pathName == name)
        this.curIndex = name == 'diagnoseHistory' ? 2 : this.curIndex
        this.curIndex = name == 'report' ? 2 : this.curIndex
      }
    }
  }
</script>

<style lang="less" scoped>
.main-home {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 70px);
  padding-top: 62px;
  flex: 1;
  overflow: auto;
  background-color: #f5f5f5;

  .main {
    display: flex;
    width: 1200px;
    margin: 24px auto 20px;
  }

  .main-left {
    width: 320px;
    height: 465px;
    background-color: #fff;
    border-radius: 8px;
    margin-right: 16px;
    flex-shrink: 0;
  }

  .main-right {
    flex: 1;
  }
}

.user-wrap {
  width: 100%;
  height: 170px;
  padding-top: 24px;
  box-sizing: border-box;
  border-bottom: 1px solid #EBEDF0;

  .user-avatar {
    width: 64px;
    height: 64px;
    display: block;
    margin: 0 auto;
    cursor: pointer;
  }

  .user-name {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #47515C;
    text-align: center;
    margin-top: 8px;
  }

  .phone {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    margin-top: 4px;
    color: #47515C;
    text-align: center;
  }
}

.list {
  width: 100%;
  padding: 24px 60px 0;
  box-sizing: border-box;

  .list-item {
    width: 100%;
    height: 40px;
    line-height: 38px;
    color: #19B2AD;
    border: 1px solid #19B2AD;
    font-size: 14px;
    text-align: center;
    margin-bottom: 24px;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 2px;
    cursor: pointer;

    &.active {
      background-color: #19B2AD;
      color: #fff;
    }
  }
}
</style>
