<template>
  <div class="main-home">
    <div class="main">
      <div class="main-right">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Main',
  data() {
    return {
      curIndex: 0
    }
  },
  created() { },
  computed: {
      ...mapState({
        userInfo: state => state.user.userInfo,
        isLogin: state => state.user.isLogin
      }),
    },
    methods: {
    }
  }
</script>

<style lang="less" scoped>
.main-home {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 70px);
  padding-top: 62px;
  flex: 1;
  overflow: auto;
  background-color: #f5f5f5;

  .main {
    display: flex;
    width: 1020px;
    height: calc(100vh - 180px);
    margin: 24px auto 24px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 8px;
  }

  .main-right {
    width: 1020px;
    flex: 1;
    padding: 24px;
    box-sizing: border-box;
  }
}
.main-left-title {
  width: 100%;
  height: 56px;
  line-height: 56px;
  padding-left: 22px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #252626;
}
.left-tab {
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  padding-left: 20px;
  cursor: pointer;
  box-sizing: border-box;
  &.active {
    background: #F0FCFC;
    .left-tab-txt {
      font-weight: 500;
      color: #19B2AD;
    }
  }
  .left-tab-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  .left-tab-txt {
    height: 24px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #47515C;
    line-height: 24px;
  }
}
</style>
